<template>
    <v-layout wrap>
        <v-flex
            class="d-flex justify-space-between flex-column flex-md-row"
            xs12
        >
            <span class="display-1 px-5 pb-5 font-weight-medium">{{
                $route.meta.name
            }}</span>
            <v-btn
                v-if="$auth.check()"
                @click="add"
                color="secondary"
                class="mb-2 mb-md-0"
                >Adaugă Funcționar</v-btn
            >
        </v-flex>
        <v-layout wrap>
            <v-layout justify-center style="width: 100%">
                <v-card class="text-center pa-5" hover width="500px">
                    <img
                        :src="imageSrc(mayor)"
                        :height="
                            $vuetify.breakpoint.smAndUp ? '400px' : '250px'
                        "
                        style="border-radius: 100%"
                        :width="$vuetify.breakpoint.smAndUp ? '400px' : '250px'"
                    />
                    <v-card-text class="text-center text-no-wrap">
                        <div class="headline font-weight-medium">
                            {{ mayor.name }}
                        </div>
                        <div class="subtitle-1">{{ mayor.title }}</div>
                    </v-card-text>
                </v-card>
            </v-layout>
            <v-layout class="mr-5" wrap>
                <v-flex
                    :key="employee.id"
                    class="pl-5 my-5"
                    lg4
                    md6
                    v-for="employee in employees"
                    xs12
                >
                    <v-card class="pa-5 mx-5 text-center" hover>
                        <img
                            :src="imageSrc(employee)"
                            :height="
                                $vuetify.breakpoint.smAndUp ? '300px' : '100px'
                            "
                            style="border-radius: 100%"
                            :width="
                                $vuetify.breakpoint.smAndUp ? '300px' : '100px'
                            "
                        />
                        <v-card-text class="text-center text-no-wrap">
                            <div class="headline font-weight-medium">
                                {{ employee.name }}
                            </div>
                            <div class="subtitle-1">{{ employee.title }}</div>
                        </v-card-text>
                        <v-layout v-if="$auth.check()">
                            <v-flex>
                                <v-btn
                                    block
                                    @click="destroy(employee)"
                                    color="error"
                                    tile
                                    x-small
                                    >Șterge</v-btn
                                >
                            </v-flex>
                            <v-flex>
                                <v-btn
                                    block
                                    @click="edit(employee)"
                                    color="success"
                                    tile
                                    x-small
                                    >Editează</v-btn
                                >
                            </v-flex>
                        </v-layout>
                    </v-card>
                </v-flex>
            </v-layout>
        </v-layout>
    </v-layout>
</template>

<script>
import "vue-organization-chart/dist/orgchart.css";

export default {
    name: "TownHall",
    metaInfo: {
        title: "Organigrama -"
    },
    components: {},
    data: () => ({
        mayor: {},
        employees: []
    }),
    mounted() {
        this.getData();
    },
    methods: {
        edit(item) {
            this.$router.push({
                name: "edit-employee",
                query: {
                    id: item.id
                }
            });
        },
        async destroy(item) {
            const res = await this.$confirm({
                message: "Sunteți sigur(ă) că vreți să ștergeți funcționarul?"
            });
            if (res) {
                try {
                    await this.axios.post(`/employees/${item.id}`, {
                        _method: "DELETE"
                    });
                    const index = this.employees.indexOf(item);
                    this.employees.splice(index, 1);
                } catch (e) {
                    return;
                }
            }
        },
        imageSrc(data) {
            return data.src
                ? data.src
                : `${require("@/assets/person_placeholder.png")}`;
        },
        add() {
            this.$router.push({
                name: "add-employee"
            });
        },
        async getData() {
            const response = await this.axios.get("/employees");
            this.mayor = response.data.mayor;
            this.employees = response.data.employees;
        }
    }
};
</script>

<style lang="scss">
.orgchart-container {
    position: relative;
    display: inline-block;
    height: 100%;
    width: 100%;
    border: none;
    border-radius: 5px;
    overflow: auto;
    text-align: center;
}

.orgchart {
    height: auto;

    .node.focused,
    .node:hover {
        background-color: rgba(0, 0, 0, 0);
    }

    .node {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        display: inline-block;
        position: relative;
        margin: 0;
        padding: 3px;
        border: 2px dashed transparent;
        text-align: center;
        width: auto !important;
    }

    .lines .topLine {
        border-top: 2px solid var(--v-secondary-base);
    }

    .lines .leftLine {
        border-left: 1px solid var(--v-secondary-base);
        float: none;
        border-radius: 0;
    }

    .lines .downLine {
        background-color: var(--v-secondary-base);
        margin: 0 auto;
        height: 20px;
        width: 2px;
        float: none;
    }
}
</style>
